* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.terminal-window {
  font-family: "Noto Sans Mono", monospace;
}
a:-webkit-any-link {
  color: white;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  background-color: #555;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #555;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(129, 129, 129);
}

.terminal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 850px;
  height: 500px;
  overflow: hidden;
  border-radius: 7px;
  border: 1px solid gray;
  cursor: text;
  background-color: rgb(19, 11, 12);
  z-index: 10;
}

.top-bar {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #424040;
  padding: 8px;
  height: 24px;
}

.button-container {
  position: absolute;
  display: flex;
  flex-direction: row;
}

.button {
  cursor: pointer;
  background-color: white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 2px;
}

.red {
  background-color: #f5544d;
}

.yellow {
  background-color: #fabd2f;
}

.green {
  background-color: #47d043;
}

.title {
  flex: 1;
  text-align: center;
  font-size: 13px;
  text-decoration: underline;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 13px;
  color: #9c9c9c;
}
/* Review this after */
p {
  font-size: 16px;
  color: rgb(238, 238, 238);
  padding: 0px 0;
}

h2 {
  font-size: 16px;
  color: rgb(238, 238, 238);
}

p.code {
  color: #66c2cd;
  margin-left: 8px;
}

p.error {
  color: red;
  margin-left: 8px;
}

p.link {
  color: #66c2cd;
  text-decoration: underline;
}

span.text {
  margin-left: 16px;
  color: white;
}

p.path {
  color: #f7fca0;
}

p.path span {
  color: #6a77d2;
}

p.path span + span {
  color: #9ceaf3;
}

.sucess {
  color: #5ad786;
}

.error {
  color: #f78f85;
}

p.response {
  color: rgb(238, 238, 238);
}

input {
  border: none;
  background-color: unset;
  color: rgb(238, 238, 238);
  width: 100%;
  font-size: 16px;
  font-family: "Fira Code", monospace;
}

input:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: unset;
}

.type {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.type2 {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.icone {
  color: #5ad786;
  padding-right: 8px;
}

.icone.error {
  color: #f78f85;
}
#app {
  display: flex;
  flex-direction: column;
  background-color: #282a35;
  width: 100%;
  height: 100%;
  padding: 8px;
  overflow: auto;
}

.display-none {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 5;
}

a {
  text-decoration: none;
}
