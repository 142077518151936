:root {
  --font-mono: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
}

section {
  background-color: #1f3140;
  padding-top: 7%;
  padding-bottom: 7%;
}

.about-background {
  min-height: 60vh;
}

.about-content {
  color: #ccd6f6;
  font-size: clamp(10px, 5vw, 0.875rem);
}

.about-text {
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  line-height: 165%;
}

ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.numbered-heading::before {
  position: relative;
  counter-increment: section 1;
  content: "0" counter(section) ".";
  margin-right: 3px;
  color: #6b98bf;
  font-family: var(--font-mono);
  font-size: clamp(10px, 5vw, 16px);
  font-weight: 700;
}

.numbered-heading {
  width: 100%;
  color: #ccd6f6;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  font-weight: 700;
  font-size: clamp(10px, 5vw, 16px);
  white-space: nowrap;
  justify-content: center;
  margin-bottom: 0px !important;
}

.about-header {
  /* text-align: center; */
  font-size: clamp(27px, 5vw, 40px);
  font-weight: 700;
  line-height: 1.4;
}
