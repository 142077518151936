* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --fz-xxs: 12px;
  --fz-xs: 13px;
  --fz-sm: 14px;
  --fz-md: 16px;
  --fz-lg: 18px;
  --fz-xl: 20px;
  --font-mono: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
}

.main-section {
  height: 100vh;
}

.main-background {
  min-height: 100vh;
  align-items: center;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Text Classes */
.start-text {
  margin: 0px 0px 30px 4px;
  font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
  font-family: var(--font-mono);
  font-weight: 300;
  color: #5e88bf;
}

.big-text {
  color: #6b98bf;
  margin: 0px;
  font-size: clamp(30px, 7vw, 70px);
  font-weight: 600;
  line-height: 1.1;
}

.small-text {
  margin: 20px 0px 0px;
  max-width: 540px;
}

.highlight-text {
  color: #617c8c;
}

/* Button Styling */
.main-button {
  color: #6b98bf;
  border: 1px solid #5e88bf;
  border-radius: 8px;
  padding: 1.25rem 1.75rem;
  font-size: var(--fz-sm);
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  margin-top: 25px;
  margin-right: 35px;
  position: relative;
}

.hvr-shutter-in-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #5e88bf;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-shutter-in-horizontal:before {
  border: 1px solid #5e88bf;
  border-radius: 8px;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0d1c26;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-shutter-in-horizontal:hover,
.hvr-shutter-in-horizontal:focus,
.hvr-shutter-in-horizontal:active {
  color: #ffffff;
}
.hvr-shutter-in-horizontal:hover:before,
.hvr-shutter-in-horizontal:focus:before,
.hvr-shutter-in-horizontal:active:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.company-name {
  color: #617c8c !important;
}

p > a:hover {
  color: #d50032 !important;
}

p > a {
  text-decoration: none;
}

/* Typewriter Classes */
.typewriter {
  color: #6b98bf;
  margin: 0px;
  font-size: clamp(30px, 7vw, 70px);
  font-weight: 600;
  line-height: 1.1;
}

.cursor {
  color: #617c8c;
  font-size: clamp(30px, 7vw, 70px);
  font-weight: 800;
}
